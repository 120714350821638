<template>
    <b-card
            class="category-edit-wrapper"
    >
        <!-- form -->
        <b-form id="categoryForm" class="edit-form mt-2">
            <b-row>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="父ID"
                            label-for="parent_id"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="parent_id"
                                size="sm"
                                v-model="category.parent_id"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="层级"
                            label-for="level"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="level"
                                size="sm"
                                v-model="category.level"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="路径"
                            label-for="path"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="path"
                                size="sm"
                                v-model="category.path"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="名称"
                            label-for="name"
                            label-size="sm"
                            class="mb-1 required"
                    >
                        <b-form-input
                                id="name"
                                size="sm"
                                v-model="category.name"
                        />
                    </b-form-group>
                </b-col>

              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="ERP编码"
                    label-for="erp_code"
                    label-size="sm"
                    class="mb-1 "
                >
                  <b-form-input
                      id="erp_code"
                      size="sm"
                      v-model="category.erp_code"
                  />
                </b-form-group>
              </b-col>

                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="ICON"
                            label-for="icon"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="icon"
                                size="sm"
                                v-model="category.icon"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="图片"
                            label-for="image"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="image"
                                size="sm"
                                v-model="category.image"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="Banner"
                            label-for="banner"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="banner"
                                size="sm"
                                v-model="category.banner"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="位置"
                            label-for="position"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="position"
                                size="sm"
                                v-model="category.position"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="简述"
                            label-for="short_description"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="short_description"
                                size="sm"
                                v-model="category.short_description"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="描述"
                            label-for="description"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="description"
                                size="sm"
                                v-model="category.description"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="显示方式"
                            label-for="display_mode"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="display_mode"
                                size="sm"
                                v-model="category.display_mode"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="落地页"
                            label-for="landing_page"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="landing_page"
                                size="sm"
                                v-model="category.landing_page"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="属性集ID"
                            label-for="attribute_set_id"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="attribute_set_id"
                                size="sm"
                                v-model="category.attribute_set_id"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="是否是菜单"
                            label-for="include_in_menu"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="include_in_menu"
                                size="sm"
                                v-model="category.include_in_menu"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="自分类数量"
                            label-for="children_count"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="children_count"
                                size="sm"
                                v-model="category.children_count"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="自定义样式"
                            label-for="custom_design"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="custom_design"
                                size="sm"
                                v-model="category.custom_design"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="状态"
                            label-for="state"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="state"
                                size="sm"
                                v-model="category.state"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="添加时间"
                            label-for="add_time"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="add_time"
                                size="sm"
                                v-model="category.add_time"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="是否专题"
                            label-for="is_special"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="is_special"
                                size="sm"
                                v-model="category.is_special"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3" class="hidden">
                    <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label="商品IDs"
                            label-for="product_ids"
                            label-size="sm"
                            class="mb-1"
                    >
                        <b-form-input
                                id="product_ids"
                                size="sm"
                                v-model="category.product_ids"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                        cols="12"
                        class="mt-50"
                >
                    <b-button
                            variant="primary"
                            class="mr-1"
                            @click="save"
                    >
                        保存
                    </b-button>
                    <b-button
                            variant="outline-secondary"
                            @click="cancel"
                    >
                        取消
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        <!--/ form -->
    </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import categoryStore from './categoryStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      category: ref({}),
      selectOptions: [
        {value: '1', label: '111'},
        {value: '2', label: '222'},
        {value: '3', label: '333'},
      ],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('category')) store.unregisterModule('category')
    })

    const edit = function() {
      store.dispatch('category/edit', {id: this.id}).then(res => {
        this.category = res.data.data
      })
    }

    const view = function() {
      store.dispatch('category/view', {id: this.id}).then(res => {
        this.category = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      this.category.state = 1
      if (this.category.name===undefined||this.category.name===""){
        toast.error("请填写名称!")
        return false
      }
      store.dispatch('category/save', this.category).then(res => {
        if (res.data.code==0){
          toast.success('数据保存成功!')
          this.$router.push({ name: 'apps-category-list'});
        }else {
          toast.error(res.data.data)
        }

      })
    }

    return {
      edit,
      view,
      cancel,
      save,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
